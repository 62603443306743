<template>
  <div>
    <page-header
      :background="require('@/assets/leagues/img.png')"
      title="Nossos patrocinadores"
    />

    <v-container class="py-16">
      <v-row justify="space-around">
        <v-col v-for="(item, i) in sponsors" cols="3" :key="i">
          <div class="d-flex flex-column fill-height zoom">
            <v-img
              :src="require(`@/assets/sponsors/${item.image}.png`)"
              :class="{ 'mb-6': item.spacing }"
              contain
            />

            <div class="d-flex justify-center">
              <v-btn
                class="rounded-l-0 rounded-r-lg text-none"
                color="primary"
                target="_blank"
                :href="item.url"
                outlined
                large
              >
                Saiba mais
              </v-btn>
            </div>
          </div>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
export default {
  data() {
    return {
      sponsors: [
        {
          label: "O melhor centro e-Sportivo do Brasil",
          image: "arenajoguefacil",
          url: "https://arenajoguefacil.com.br/#/",
        },
        {
          label: "A melhor casa do Brasil",
          image: "joguefacil",
          url: "https://joguefacil.bet/#/",
        },
        {
          label: "",
          image: "uninassau",
          url: "https://www.uninassau.edu.br/",
          spacing: true,
        },
      ],
    };
  },
};
</script>

<style></style>
